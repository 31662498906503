import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import Button from '@oup/shared-front-end/src/components/Button';
import env from '../../../../globals/envSettings';
import session from '../../../../utils/session';
import withLocalizedContent from '../../../../language/withLocalizedContent.js';
import styles from './OicLaunchJanison.scss';
import { PopoutPanelIconHeading } from '../../../../components/index.js';
import { types } from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';

function OicLaunchJanison({ localizedContent: { oicRedirectToJanison: localizedContent = {} } }) {
  const redirectTo = env.integrations.oic_janison.ssoLoginUrl;
  const redirect = () => window.location.replace(redirectTo);

  const [isSessionExpiringSoon, setIsSessionExpiringSoon] = useState(false);

  React.useEffect(() => {
    const currentTimeinMilliseconds = new Date().getTime();
    const sessionInfo = session.get();
    const sessionExpiresAt = parseInt(sessionInfo.oidc?.sessionClock ?? 0, 10);
    const soonInMilliseconds = currentTimeinMilliseconds + 30 * 1000;
    const sessionExpiresSoon = soonInMilliseconds > sessionExpiresAt;
    setIsSessionExpiringSoon(sessionExpiresSoon);

    if (!sessionExpiresSoon) {
      redirect();
    }
  }, []);

  const renderHeading = () => (
    <div className="col cols-center">
      <h4 id="invitationHeading">
        <div>
          <p>Your session expires soon. Please sign in again.</p>
        </div>
      </h4>
    </div>
  );

  const renderOIDCSessionIsExpiringSoonWarningContent = () => (
    <div className={styles.oicLaunchJanison}>
      <div className="grid">
        <div className={classnames('row', styles.assessmentsHeading)}>{renderHeading()}</div>
        <div className="col sm-cols-center">
          <div className="col">
            <div className="row cols-center">
              <div className="col">
                <Button variant="filled" onClick={redirect} text={localizedContent.confirm_login_action} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderRedirectToJanisonContent = () => (
    <div className="pad-top4">
      <div className={classnames('grid justify-content-between', styles.assessementsTab)}>
        <div className="row">
          <div className="col align-items-center">
            <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              <PopoutPanelIconHeading
                type={types.LOADING}
                title={localizedContent.headline}
                subtitle={localizedContent.info_label}
                link={redirectTo}
                linkText={localizedContent.call_to_action}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>{localizedContent.page_title}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      {isSessionExpiringSoon ? renderOIDCSessionIsExpiringSoonWarningContent() : renderRedirectToJanisonContent()}
    </>
  );
}

OicLaunchJanison.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withLocalizedContent('oicRedirectToJanison'),
  connect(() => {}, {})
)(OicLaunchJanison);
